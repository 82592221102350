<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Content -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <TabView v-model:active-index="tabIndex">
                        <TabPanel header="Hard Exclude">
                            <p class="text-xl font-bold">Hard Exclude</p>

                            <!-- add new -->
                            <InputText v-model="newHardExclude" type="text" class="w-full mt-2" placeholder="Add new hard exclude" />
                            <Button class="w-full mt-2" label="Create" @click="addNewHardExclude" />

                            <DataTable :value="hardExcludeData" dataKey="domain" :loading="hardExcludeLoading"
                                       v-model:filters="hardExcludeFilters"  filter-display="menu" class="mt-2"
                                       @page="onHardExcludePageChange($event)" @filter="onHardExcludeFilter($event)"
                                       paginator :rows="100" :rows-per-page-options="[100, 200, 500]" :total-records="hardExcludeTotalRecords"
                                       paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                       lazy stripedRows scrollable resizableColumns columnResizeMode="fit" show-gridlines>
                                <template #loading> Loading hard exclude data. Please wait. </template>
                                <Column field="domain" header="Domain" :showFilterMatchModes="false">
                                    <template #body="{ data }">
                                        {{ data.domain }}
                                    </template>
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by name" />
                                    </template>
                                </Column>
                                <Column field="type" header="Type" style="min-width: 12rem"/>
                                <Column value="Delete" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                                    <template #body="{ data }">
                                        <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                                            <i class="pi pi-trash" @click="deleteHardExclude($event, data)"/>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                        </TabPanel>

                        <TabPanel header="Suspicious Domains">
                            <p class="text-xl font-bold">Suspicious Domains</p>

                            <!-- add new -->
                            <InputText v-model="newSuspiciousDomain" type="text" class="w-full mt-2" placeholder="Add new suspicious domain" />
                            <Button class="w-full mt-2" label="Create" @click="addNewSuspiciousDomain" />

                            <DataTable :value="suspiciousDomainsData" dataKey="domain" :loading="suspiciousDomainsLoading"
                                       v-model:filters="suspiciousDomainsFilters"  filter-display="menu" class="mt-2"
                                       @page="onSuspiciousDomainsPageChange($event)" @filter="onSuspiciousDomainsFilter($event)"
                                       paginator :rows="100" :rows-per-page-options="[100, 200, 500]" :total-records="suspiciousDomainsTotalRecords"
                                       paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                       lazy stripedRows scrollable resizableColumns columnResizeMode="fit" show-gridlines>
                                <template #loading> Loading suspicious domains data. Please wait. </template>
                                <Column field="domain" header="Domain" :showFilterMatchModes="false">
                                    <template #body="{ data }">
                                        {{ data.domain }}
                                    </template>
                                    <template #filter="{ filterModel, filterCallback }">
                                        <InputText v-model="filterModel.value" type="text" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by name" />
                                    </template>
                                </Column>
                                <Column field="type" header="Type" style="min-width: 12rem"/>
                                <Column value="Delete" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                                    <template #body="{ data }">
                                        <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                                            <i class="pi pi-trash" @click="deleteSuspiciousDomain($event, data)"/>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {FilterMatchMode} from "primevue/api";

export default {
    name: "Filters",

    data() {
        return {
            tabIndex: 0,
            settings: [],
            selectedSetting: null,

            hardExcludeLoading: false,
            suspiciousDomainsLoading: false,

            hardExcludeTotalRecords: 0,
            suspiciousDomainsTotalRecords: 0,

            hardExcludeData: [],
            suspiciousDomainsData: [],

            hardExcludeFilters: {
                domain: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            suspiciousDomainsFilters: {
                domain: { value: null, matchMode: FilterMatchMode.CONTAINS }
            },

            newHardExclude: "",
            newSuspiciousDomain: "",

            hardExcludeLazyParams: {
                first: 0,
                rows: 100,
                sortField: "",
                sortOrder: "",
                filters: {
                    domain: { value: null, matchMode: FilterMatchMode.CONTAINS }
                }
            },
            suspiciousDomainsLazyParams: {
                first: 0,
                rows: 100,
                sortField: "",
                sortOrder: "",
                filters: {
                    domain: { value: null, matchMode: FilterMatchMode.CONTAINS }
                }
            }
        }
    },

    mounted() {
        this.getHardExcludeData();
        this.getSuspiciousDomainsData();
    },

    methods: {
        viewSetting(setting) {
            console.debug(setting);
        },

        getHardExcludeData() {
            this.hardExcludeLoading = true;
            this.$factory.filters.getHardExcludeData(this.hardExcludeLazyParams).then(response => {
                this.hardExcludeData = response.data;
                this.hardExcludeTotalRecords = response.totalRecords;

                console.debug(this.hardExcludeData)
            }).catch(
                error => {
                    console.error(error);
                }
            ).finally(() => {
                this.hardExcludeLoading = false;
            })
        },

        onHardExcludePageChange(event) {
            this.hardExcludeLazyParams = event;
            this.getHardExcludeData();
        },

        onHardExcludeFilter() {
            this.hardExcludeLazyParams.filters = this.hardExcludeFilters;
            this.getHardExcludeData();
        },

        deleteHardExclude(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this entry?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.filters.deleteHardExclude(data.domain).then(() => {
                        this.getHardExcludeData();
                        this.$emitter.emit('good-toast', data.domain + ' deleted');
                    }).catch(error => {
                        console.error(error);
                        this.$emitter.emit('bad-toast', 'Failed to delete ' + data.domain);
                    })
                },
            });
        },

        getSuspiciousDomainsData() {
            this.suspiciousDomainsLoading = true;
            this.$factory.filters.getSuspiciousDomainsData(this.suspiciousDomainsLazyParams).then(response => {
                this.suspiciousDomainsData = response.data;
                this.suspiciousDomainsTotalRecords = response.totalRecords;
            }).catch(
                error => {
                    console.error(error);
                }
            ).finally(() => {
                this.suspiciousDomainsLoading = false;
            })
        },

        onSuspiciousDomainsPageChange(event) {
            this.suspiciousDomainsLazyParams = event;
            this.getSuspiciousDomainsData();
        },

        onSuspiciousDomainsFilter() {
            this.suspiciousDomainsLazyParams.filters = this.suspiciousDomainsFilters;
            this.getSuspiciousDomainsData();
        },

        deleteSuspiciousDomain(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this entry?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.filters.deleteSuspiciousDomain(data.domain).then(() => {
                        this.getSuspiciousDomainsData();
                        this.$emitter.emit('good-toast', data.domain + ' deleted');
                    }).catch(error => {
                        console.error(error);
                        this.$emitter.emit('bad-toast', 'Failed to delete ' + data.domain);
                    })
                },
            });

        },

        addNewHardExclude() {
            this.$factory.filters.createHardExclude(this.newHardExclude).then(() => {
                this.getHardExcludeData();
                this.$emitter.emit('good-toast', this.newHardExclude + ' added');
                this.newHardExclude = "";
            }).catch(error => {
                console.error(error);
                this.$emitter.emit('bad-toast', 'Failed to add ' + this.newHardExclude);
            });
        },

        addNewSuspiciousDomain() {
            this.$factory.filters.createSuspiciousDomain(this.newSuspiciousDomain).then(() => {
                this.getSuspiciousDomainsData();
                this.$emitter.emit('good-toast', this.newSuspiciousDomain + ' added');
                this.newSuspiciousDomain = "";
            }).catch(error => {
                console.error(error);
                this.$emitter.emit('bad-toast', 'Failed to add ' + this.newSuspiciousDomain);
            });
        }

    },

    watch: {
        tabIndex() {
            console.debug(this.tabIndex);
        }
    }
}
</script>

<style scoped>

</style>
